import { Options, Vue }     from "vue-class-component";
import { Prop }             from "vue-property-decorator";
import { messagesService }  from "@services/messages.service";
import { SendMessageModel } from "@/model/ChatMessage";

@Options( {} )
export default class SendMessageDialog extends Vue {
    @Prop()
    userId: number;

    @Prop()
    hint: string;

    @Prop()
    text: string;

    @Prop( { default: false } )
    all: boolean;

    message: SendMessageModel = new SendMessageModel();

    onShow() {
        this.text && (this.message.text = this.text);
    }

    async send() {
        await this.$waitFor( async () => {
            await messagesService.sendMessageToEntities( this.message.text, [ this.userId ] );
            this.$successMessage( "Messaggio inviato" );
            this.$emit( "sent" );
            this.reset();
            this.close();

        } );
    }

    private reset() {
        this.message = new SendMessageModel();
    }

    private close() {
        this.$emit( 'update:visible', false );
    }
}
