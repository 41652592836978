import { CRUD }                from './base/crud';
import { ChatMessage, Thread } from "@/model/ChatMessage";

class MessagesService extends CRUD<Thread, any> {
    readonly endPoint = `chat/threads`;

    public unreadCount() {
        return this.get<{ count: number }>( `${ this.endPoint }/unread-count` );
    }


    public threadMessages( threadId: number ) {
        return this.get<ChatMessage[]>( `${ this.endPoint }/${ threadId }` );
    }


    public sendMessageToThread( threadId: number, text: string ) {
        return this.post<ChatMessage>(
            `${ this.endPoint }/${ threadId }/send-message`,
            { text }
        );
    }

    public sendMessageToEntities( text: string, entity_ids: number[] ) {
        return this.post(
            `${ this.endPoint }/send-message-entities`,
            { entity_ids, text }
        );
    }

    /* API AS CUSTOMER/SUPPLIER/TAKER */

    public unreadCountAsClient() {
        return this.get<{ count: number }>( `chat/unread-count` );
    }

    public messages() {
        return this.get<ChatMessage[]>( `chat/messages` );
    }

    public sendMessage( text: string ) {
        return this.post<ChatMessage>(
            `chat/send-message`,
            {
                text,
            }
        );
    }
}

export const messagesService = new MessagesService();



