import { EntityPickersFilter } from "@/model/filters/EntityPickersFilter";
import { entityPickersService } from "@services/entityPickers.service";
import { FilterMatchMode } from "primevue/api";
import { defineComponent, ref, toRefs, watch } from "vue";

export default defineComponent({
  name: "PickerAutocomplete",

  inheritAttrs: false,

  props: [
    'modelValue', 
    'customerId',
    'zoneId',
    "type"
  ],

  emits: ['resultSelected'],

  setup(_, { emit }) {
    const { 
      modelValue,
      customerId,
      zoneId,
      type
    } = toRefs(_);

    const searchBox   = ref('');
    const suggestions = ref(null);
    const selected    = ref(null);

    async function search({ query }) {
      const f = new EntityPickersFilter();
      f.entity_id = customerId.value; 

      f.filters = {
        global: {
          value: query,
          matchMode: FilterMatchMode.CONTAINS
        },
        zone_id: {
          value     : zoneId.value,
          matchMode : FilterMatchMode.EQUALS
        },
        type: {
          value     : type.value,
          matchMode : FilterMatchMode.EQUALS
        },
      };

      const response = await entityPickersService.index(f);

      suggestions.value = response.data;
    }

    async function onSelect({ value }) {
        selected.value = value; 
        emit('resultSelected', selected.value);
    }

    function onChange(e: Event){
      const v = (e.target as HTMLInputElement).value;
      if(!v) {
        emit('resultSelected', null);
      }
    }

    watch(
      modelValue,
      () => {
        searchBox.value = modelValue.value;
      },
      {
        immediate: true
      }
    );

    return {
      searchBox,
      suggestions,

      onSelect,
      onChange,
      search,
    }
  }
})