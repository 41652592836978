import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ce160bcc")
const _hoisted_1 = { class: "price_and_cost_container" }
const _hoisted_2 = { class: "p-mb-2" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config, (c, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "price_and_cost",
        key: `${c.title}_${i.toString()}`
      }, [
        _createElementVNode("header", null, [
          _createElementVNode("h6", _hoisted_2, _toDisplayString(c.title), 1)
        ]),
        (c.priceKey)
          ? (_openBlock(), _createBlock(_component_FloatLabel, {
              key: 0,
              label: c.priceLabel
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputNumber, {
                  mode: "currency",
                  currency: "EUR",
                  class: "medium_input",
                  modelValue: _ctx.value[c.priceKey],
                  "onUpdate:modelValue": ($event: any) => (_ctx.value[c.priceKey] = $event),
                  disabled: _ctx.isReadonly
                }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (c.costKey)
          ? (_openBlock(), _createBlock(_component_FloatLabel, {
              key: 1,
              label: c.costLabel
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputNumber, {
                  mode: "currency",
                  currency: "EUR",
                  class: "medium_input",
                  modelValue: _ctx.value[c.costKey],
                  "onUpdate:modelValue": ($event: any) => (_ctx.value[c.costKey] = $event),
                  disabled: _ctx.isReadonly
                }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}