import { defineComponent, toRefs, watch } from "vue";

import { Taker }           from "@/model/Taker";
import { useAutocomplete } from "@/use/useAutocomplete";
import { takersService }   from "@services/takers.service";

export default defineComponent({
  inheritAttrs: false,

  props: [ 'modelValue' ],

  emits: [
    'resultSelected',
    'update:modelValue'
  ],

  setup( _, { emit } ) {
    const { modelValue } = toRefs( _ );

    const {
            onSelect,
            search,
            searchBox,
            suggestions,
          } = useAutocomplete<Taker>(
      takersService,
      (taker: Taker) => {
        emit( 'update:modelValue', taker );
        emit( 'resultSelected', taker );
      }
    );

    function onChange(e: Event){
      const v = (e.target as HTMLInputElement).value;
      if(!v) {
        emit( 'update:modelValue', null );
        emit( 'resultSelected', null );
      }
    }

    watch(
      modelValue,
      () => {
        searchBox.value = modelValue.value;

      },
        {
          immediate: true,
        }
    );

    function customField( t: Taker ) {
      return `${ t.business_name } - ${ t.name }`;
    }

    function clear() {
      searchBox.value = null;
      emit( 'update:modelValue', null );
      emit( 'resultSelected', null );
    }

    return {
      searchBox,
      suggestions,

      customField,
      onSelect,
      onChange,
      search,
      clear,
    }
  }
})
