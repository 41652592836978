import { BaseApiFilter } from "@/model/filters/BaseApiFilter";
import { CRUD } from "@services/base/crud";
import { ref } from "vue";

export function useAutocomplete<T>(service: CRUD<T, any>, afterSelect?: (item: T) => void) {
    const searchBox   = ref('');
    const suggestions = ref(null);
    const selected    = ref(null);

    async function search({ query }) {
        const f = new BaseApiFilter();
        f.q = query;

        const response = await service.index(f);

        suggestions.value = response.data;
    }

    async function onSelect({ value }) {
        selected.value = value; 

        if(afterSelect){
            afterSelect(value);
        }
    }

    return {
        searchBox,
        suggestions,
        selected,

        search,
        onSelect
    }
}

export function useAutocompleteAndGetDetail<T>(args: Args<T>) {
    const { service, afterSelect } = args;

    const {
        searchBox,
        suggestions,
        selected,
        search,
    } = useAutocompleteWithParams(args);

    async function onSelect({ value }) {
        selected.value = await service.getById(value.id);

        if(afterSelect){
            afterSelect(selected.value);
        }
    }

    return {
        searchBox,
        suggestions,
        selected,

        search,
        onSelect
    }
}

interface Args<T> {
    service: CRUD<T, any>; 
    params?: any;
    afterSelect?: (item: T) => void;
}

export function useAutocompleteWithParams<T>(
    { service, params, afterSelect }: Args<T>
) {
    const searchBox   = ref('');
    const suggestions = ref(null);
    const selected    = ref(null);

    async function search({ query }) {
        const f = new BaseApiFilter();
        f.q = query;

        if (params) {
            f.filters = params?.value || params;
        }

        const response = await service.index(f);

        suggestions.value = response.data;
    }

    async function onSelect({ value }) {
        selected.value = value; 

        if (afterSelect) {
            afterSelect(value);
        }
    }

    return {
        searchBox,
        suggestions,
        selected,

        search,
        onSelect
    }
}