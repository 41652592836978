import { defineComponent, toRefs, watch } from "vue";
import { useAutocompleteWithParams }      from "@/use/useAutocomplete";
import { zonesService }                   from "@services/zones.service";
import { Zone }                           from "@/model/Zone";
import { FilterMatchMode }                from "primevue/api";


export default defineComponent({
  inheritAttrs: false,

  props: ['modelValue', 'showClear'],

  emits: ['resultSelected', 'update:modelValue'],

  setup(_, { emit }) {
    const { modelValue } = toRefs(_);

    const {
      onSelect,
      search,
      searchBox,
      suggestions,
    } = useAutocompleteWithParams<Zone>({
      service: zonesService,
      params: {
        is_active: {
          value: true,
          matchMode: FilterMatchMode.EQUALS
        }
      },
      afterSelect: (zone: Zone) => {
        emit( 'update:modelValue', zone );
        emit( 'resultSelected', zone );
      }
    });

    function onChange(e: Event) {
      const v = (e.target as HTMLInputElement).value;
      if (!v) {
        emit( 'update:modelValue', null );
        emit( 'resultSelected', null );
      }
    }

    watch(
      modelValue,
      () => {
        searchBox.value = modelValue?.value;
      },
      {
        immediate: true
      }
    );

    function clear() {
      searchBox.value = null;
      emit( 'update:modelValue', null );
      emit( 'resultSelected', null );
    }

    return {
      searchBox,
      suggestions,

      onSelect,
      onChange,
      search,
      clear,
    }
  }
})
