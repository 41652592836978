import { Options, Vue } from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";
import { DateRange } from 'moment-range';
import { Moment } from 'moment';

@Options({
  name: 'WeekNavigator'
})
export default class WeekNavigator extends Vue {
  @Prop() disabled: boolean;
  
  @Model('modelValue') value: DateRange;

  @Model('days') daysModel: Moment[] = null;
  
  get formattedRange() {
    return `${this.value.start.format('DD/MM/YYYY')} - `
      + `${this.value.end.format('DD/MM/YYYY')}`
  }

  /**
   * Sposta di una settimana INDIETRO il periodo di selezione 
   */
   previousWeek() {
    const clone = this.value.clone();
    clone.start.subtract(1, 'week');
    clone.end.subtract(1, 'week');

    this.value = clone;
    this.updateDays();
  }
  
  /**
   * Sposta di una settimana AVANTI il periodo di selezione 
   */
   nextWeek() {
    const clone = this.value.clone();
    clone.start.add(1, 'week');
    clone.end.add(1, 'week');

    this.value = clone;
    this.updateDays();
  }

  private updateDays() {
    this.daysModel = [...this.value.by('day')];
    this.$emit('update:days', this.daysModel);
  }

  mounted() {
    if (this.value) {
      this.updateDays();
    }
  }
}