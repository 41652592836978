
import { computed, defineComponent, ref, toRef }               from 'vue';
import { useField, useForm, useFormValues, useIsFieldTouched } from "vee-validate";
import VDropdown                                               from "@components/VDropdown/VDropdown.vue";
import VInputSwitch                                            from "@components/VInputSwitch/VInputSwitch.vue";
import VInputText                                              from "@components/VInputText/VInputText.vue";
import { EntityUser, entityUsersService }                      from "@services/entity-users.service";

export default defineComponent( {
  name: "EntityUserDialog",

  props: [
    "user",
    "entityId",
    "roleOptions"
  ],

  components: {
    VDropdown,
    VInputSwitch,
    VInputText
  },

  setup( props, { emit } ) {
    const user  = toRef( props, 'user' )
    const isNew = computed( () => !user.value?.id );

    const validationSchema = computed( () => {
      const baseValidation = {
        id     : null,
        name   : 'required',
        surname: 'required',
        email  : 'required|email',
        role   : 'required'
      };

      const ifNew = {
        password       : 'required|min:6',
        confirmPassword: 'required|confirmPwd:@password',
      }

      return isNew.value || formValue.value?.password?.length
          ? { ...baseValidation, ...ifNew }
          : baseValidation
    } )

    const {
            values, setValues,
            resetForm, setErrors, setTouched,
            handleSubmit
          } = useForm( {
      validationSchema,
    } )

    const formValue = useFormValues()

    /* Password validation */
    const { value: pwd, errors: pwdErrors } = useField<string>( 'password' )
    const pwdTouched                        = useIsFieldTouched( 'password' )

    /* Confirm password validation */
    const { value: cpwd, errors: cpwdErrors } = useField<string>( 'confirmPassword' )
    const cpwdTouched                         = useIsFieldTouched( 'confirmPassword' )

    const onSubmit = handleSubmit( async ( formValues ) => {
      await saveUser( formValues )
    } )

    const isLoading = ref( false )

    async function saveUser( user: any ) {
      try {
        isLoading.value = true

        const body: EntityUser = {
          entity_id: props.entityId,
          ...user
        }

        const response = isNew.value
            ? await entityUsersService.create( body )
            : await entityUsersService.updatePatch( body )

        emit( 'saved', response )


      }
          // eslint-disable-next-line no-useless-catch
      catch (e) {
        throw e;
      } finally {

        isLoading.value = false
      }
    }

    function onShow() {

      if (props.user) {
        setValues( props.user )
        setTouched( {} )
        setErrors( {} )
      }
    }

    function hide() {
      emit( 'update:visible', false )
    }

    function onHide() {
      resetForm()
      setErrors( {} )
      setTouched( {} )

      pwd.value  = ""
      cpwd.value = ""
    }

    return {
      values,
      onShow,
      onHide,
      hide,

      pwd, pwdErrors, pwdTouched,
      cpwd, cpwdErrors, cpwdTouched,

      onSubmit,
      isLoading
    }
  }
} )
