import { Vehicle } from "@/model/Vehicle";
import { Options, Vue } from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";

@Options({})
export default class VehicleDialog extends Vue {
  @Model('show')
  readonly showModel!: boolean; 

  @Model('vehicle') 
  readonly vehicleModel!: Vehicle;
  
  private close(){
    this.$emit('update:show', false);
  }

  onDialogCancel(){
    this.close();
    this.$emit('dialogClose');
  }

  async onDialogSave(){
    // const validationResult = await this.userForm.form.validate();
    
    // if ( !validationResult.valid ){
    //   this.$errorMessage(this.$t('validation.form_generic'));
    //   return; 
    // }

    // this.close();
    this.$emit('dialogSave');
  }
}