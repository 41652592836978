import { ZoneCustomPricing } from "@/model/Zone";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ZonePricingGrid extends Vue {
  @Prop() readonly config!: any;

  @Prop() readonly value!: ZoneCustomPricing;

  @Prop() readonly isReadonly!: ZoneCustomPricing;
}