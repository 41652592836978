import { computed, defineComponent, toRefs, watch } from "vue";

import { Customer }                  from "@/model/Customer";
import { useAutocompleteWithParams } from "@/use/useAutocomplete";
import { customersService }          from "@services/customers.service";
import { FilterMatchMode }           from "primevue/api";

export default defineComponent( {
  inheritAttrs: false,

  props: [
    'modelValue',
    "zoneId"
  ],

  emits: [
    'resultSelected',
    'update:modelValue'
  ],

  setup( _, { emit } ) {
    const {
            modelValue,
            zoneId
          } = toRefs( _ );

    const params = computed( () => {
      return {
        zoneId: {
          value: zoneId.value,
          matchMode: FilterMatchMode.CONTAINS
        }
      }
    });

    const {
      onSelect,
      search,
      searchBox,
      suggestions,
    } = useAutocompleteWithParams<Customer>({
      service: customersService,
      afterSelect: (customer: Customer) => {
        emit( 'update:modelValue', customer );
        emit( 'resultSelected', customer );
      },
      params
    } );

    function onChange( e: Event ) {
      const v = (e.target as HTMLInputElement).value;
      if (!v) {
        emit( 'update:modelValue', null );
        emit( 'resultSelected', null );
      }
    }

    function clear() {
      searchBox.value = null;
      emit( 'update:modelValue', null );
      emit( 'resultSelected', null );
    }

    watch(
        modelValue,
        () => {
          searchBox.value = modelValue.value;
        },
        {
          immediate: true
        }
    );

    return {
      searchBox,
      suggestions,

      onSelect,
      onChange,
      search,
      clear,
    }
  }
})
