import { Vehicle } from "@/model/Vehicle";
import { vehiclesService } from "@services/vehicles.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export declare class IVehicleAutocomplete {
  $props: {
    ids : number[];
  }

  $emit(eventName: 'select', value: Vehicle): any;
}

@Options({})
export default class VehicleAutocomplete extends Vue {
  @Prop({ required: true }) readonly ids!: number[];

  vehicleOptions: Vehicle[] = null;

  searchBox  : string | Vehicle = null;
  suggestions: Vehicle[]        = null;

  search({ query }) {
    const x = (query as string).toLowerCase().trim();

    this.suggestions = this.vehicleOptions
      .filter(
        v => 
          (!this.ids?.includes(v.id)) 
          && v.name_it.toLowerCase().includes(x)
      );
  }

  
  onSelect({ value }: { value: Vehicle }) {
    this.$emit('select', value);
    this.searchBox = null; 
  }

  private async loadVehicleOptions() {
    this.vehicleOptions = await vehiclesService.getAll();
  }
  
  mounted() {
    this.loadVehicleOptions();
  }
}