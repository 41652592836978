import { BaseApiFilter } from "@/model/filters/BaseApiFilter";
import { EntityPickerType } from "../Entity";

export class EntityPickersFilter extends BaseApiFilter {

    entity_id: number;

    type: EntityPickerType;

    zone_id?: number;

    constructor(data?: EntityPickersFilter) {
        super(data);
        
        if (data) {
            Object.assign(this, data);
        }
    }

}
