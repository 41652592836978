
import { useField } from "vee-validate"
import { computed, defineComponent, inject } from "vue"

export default defineComponent({
  name: "VTextArea",
  inheritAttrs: false ,

  props: ['field', 'label'],

  computed : {
    unpackedAttrs() {
      const { 
        required, 
        class: classAttribute, 
        ...otherAttributes 
      } = this.$attrs;

      return {
        otherAttributes,
        classAttribute,
        required: ("required" in this.$attrs) && required !== false,
      }
    }
  },
  
  setup(p) {
    const loading = inject<boolean>("loading")

    const isValid = computed(() => {
      return (meta.touched) && meta.valid
    })

    const isInvalid = computed(() => {
      return meta.touched && errors.value?.length
    })

    const {
            value,
            errors,
            meta,
            setTouched,
          } = useField(p.field, undefined, {
      initialValue: "",
    })

    return {
      loading, isValid, isInvalid, value, errors, meta, setTouched,
    }

  }
})


