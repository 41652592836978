import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-284ffb8c")
const _hoisted_1 = { class: "p-d-flex p-ai-center p-jc-between" }
const _hoisted_2 = { class: "price_and_cost" }
const _hoisted_3 = { class: "price_and_cost__icon" }
const _hoisted_4 = { class: "price_and_cost__inputs" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: _normalizeClass(["price_and_cost_card", { disabled : !_ctx.enabled}])
  }, {
    title: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        (_ctx.canDisable)
          ? (_openBlock(), _createBlock(_component_InputSwitch, {
              key: 0,
              class: "p-mr-3",
              modelValue: _ctx.enabled,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.enabled = $event))
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", null, [
          (_ctx.hasRemoveHandler)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "pi pi-times",
                class: "p-button-rounded p-button-text p-button-secondary",
                onClick: _ctx.removeVehicle
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_FaIcon, {
                key: 0,
                icon: _ctx.icon,
                size: "4x"
              }, null, 8, ["icon"]))
            : (_openBlock(), _createBlock(_component_FaIcon, {
                key: 1,
                icon: "dolly",
                size: "4x"
              }))
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.showPrice)
            ? (_openBlock(), _createBlock(_component_FloatLabel, {
                key: 0,
                label: _ctx.priceLabel,
                labelClass: "black_out"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.vehicleData.extra_price,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.vehicleData.extra_price = $event)),
                    mode: "currency",
                    currency: "EUR",
                    class: "small_input",
                    disabled: !_ctx.enabled
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          (_ctx.showCost)
            ? (_openBlock(), _createBlock(_component_FloatLabel, {
                key: 1,
                label: _ctx.costLabel,
                labelClass: "black_out",
                class: "p-mt-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.vehicleData.extra_cost,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.vehicleData.extra_cost = $event)),
                    mode: "currency",
                    currency: "EUR",
                    class: "small_input",
                    disabled: !_ctx.enabled
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}