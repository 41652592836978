import { Loader } from "@googlemaps/js-api-loader";
import { configuration } from "@plugins/Config-plugin";

export function useGoogleLoader(language = 'it'){
    
    const mapLoader = new Loader({
        apiKey  : configuration.mapApiKey,
        version : configuration.mapVersion,
        libraries: ["places"],
        language
    });

    async function loadGoogle(){
        if (!window.google) {
            await mapLoader.load();
        }
    }

    return {
        loadGoogle
    }

}