
import { computed, defineComponent, toRefs, watch } from "vue";
import { useField, useForm }                        from "vee-validate";

import VInputText   from "@components/VInputText/VInputText.vue";
import VInputSwitch from "@components/VInputSwitch/VInputSwitch.vue";
import VDropdown    from "@components/VDropdown/VDropdown.vue";


export default defineComponent( {
  name: "UserForm",

  inheritAttrs: false,

  components: {
    VDropdown,
    VInputSwitch,
    VInputText
  },

  props: {
    formId     : {
      type    : String,
      required: true
    },
    isLoading  : {
      type   : Boolean,
      default: false
    },
    modelValue : {
      type   : Object,
      default: () => ({})
    },
    roleOptions: {
      type   : Array,
      default: () => []
    },
    isNew      : {
      type   : Boolean,
      default: false
    }
  },

  setup( props: any, { emit } ) {
    const { modelValue, isNew } = toRefs( props );

    const validationSchema = computed( () => {
      const baseValidation = {
        id     : null,
        name   : 'required',
        surname: 'required',
        email  : 'required|email',
        role   : 'required'
      };

      const ifNew = {
        password       : 'required|min:6',
        confirmPassword: 'required|confirmPwd:@password',
      }

      return isNew.value || modelValue.value?.password?.length
          ? { ...baseValidation, ...ifNew }
          : baseValidation
    } )

    const { values: value, handleSubmit } = useForm( {
      validationSchema,
      initialValues: modelValue || null,
    } )

    const { value: pwd, errors: pwdErrors }   = useField<string>( 'password' )
    const { value: cpwd, errors: cpwdErrors } = useField<string>( 'confirmPassword' )

    watch( value, ( newValue ) => {
      emit( 'update:modelValue', newValue );
    } );

    const onSubmit = handleSubmit( v => {
      emit( 'update:modelValue', v )
      emit( 'formSubmit', v )
    } )

    return {
      value,
      onSubmit,

      pwd, pwdErrors,
      cpwd, cpwdErrors,
    }
  }
} );

