import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-field p-d-flex p-ai-center" }
const _hoisted_2 = { class: "p-ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputSwitch, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = $event)),
      trueValue: true,
      falseValue: false
    }, null, 8, ["modelValue"]),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.value ? _ctx.labelOn : _ctx.labelOff), 1)
  ]))
}