import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Password = _resolveComponent("Password")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.unpackedAttrs.classAttribute)
  }, [
    _createVNode(_component_FloatLabel, {
      label: _ctx.unpackedAttrs.required ? _ctx.label + ' *' : _ctx.label
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Password, _mergeProps({
          toggleMask: "",
          class: {
            'p-invalid': _ctx.isInvalid,
            'p-valid': _ctx.isValid
          }
        }, _ctx.unpackedAttrs.otherAttributes, {
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = $event)),
          disabled: _ctx.loading,
          onFocusin: _cache[1] || (_cache[1] = () => _ctx.setTouched(true))
        }), null, 16, ["class", "modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label"]),
    ((_ctx.meta.touched) && _ctx.errors)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.errors, (e) => {
          return (_openBlock(), _createElementBlock("p", {
            class: "p-error",
            key: `error_${_ctx.field}_${e}`
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(e)), 1)
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ], 2))
}