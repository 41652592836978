import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SendMessageDialog = _resolveComponent("SendMessageDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Button, {
      class: "p-mr-1",
      icon: "pi pi-send",
      onClick: _ctx.showMessageDialog
    }, null, 8, ["onClick"]), [
      [
        _directive_tooltip,
        'Invia messaggio',
        void 0,
        { bottom: true }
      ]
    ]),
    _createVNode(_component_SendMessageDialog, {
      visible: _ctx.displayMessageDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayMessageDialog = $event)),
      userId: _ctx.userId
    }, null, 8, ["visible", "userId"])
  ], 64))
}