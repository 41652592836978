<template>
    <PDialog
      class="p-fluid"
      :modal="true"
      header="Loading..."
      :draggable="false"
      :closable="false"
      :keepInViewPort="true"
      :minX="0"
      :minY="0"
      :style="{
        minWidth: '200px',
        width: '50vw',
        maxWidth: '300px'
      }"
      v-bind="$attrs"
    >
      <ProgressBar mode="indeterminate" />
    </PDialog>
</template>