
import { computed, defineComponent } from "vue";

import CustomerData                   from "@/modules/customers/pages/customer/CustomerData/CustomerData.vue";
import { CUSTOMER_VALIDATION_SCHEMA } from "@/modules/common/validation_schemas";
import { useForm }                    from "vee-validate";
import { useMessage }                 from "@plugins/Messages-plugin";
import { useI18n }                    from "vue-i18n";

export default defineComponent( {
  name      : "CustomerForm",
  components: { CustomerData },
  props     : [
    'customer',
    'isLoading',
  ],
  setup( _, { emit } ) {
    const { t: $t }        = useI18n()
    const { errorMessage } = useMessage()

    const validationSchema = computed( () => ({
      ...CUSTOMER_VALIDATION_SCHEMA
    }) )

    const initialValues = { is_active: true }

    const {
            values,
            validate,
            setFieldTouched
          } = useForm( { validationSchema, initialValues } )

    async function onFormSubmit() {
      const { valid } = await validate()

      if (!valid) {
        // @ts-ignore
        Object.keys( values ).forEach( key => setFieldTouched( key, true ) )
        errorMessage( $t( 'common.required_fields' ) );
        return
      }

      emit( 'save', values )
    }

    return {
      onFormSubmit
    }
  }
} )
