import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!

  return (_openBlock(), _createBlock(_component_FloatLabel, {
    label: "Fascia oraria",
    class: "w100"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dropdown, _mergeProps(_ctx.$attrs, {
        options: _ctx.timeslots,
        optionLabel: "label",
        optionValue: "value",
        placeholder: "Scegli fascia",
        showClear: ""
      }), null, 16, ["options"])
    ]),
    _: 1
  }))
}