import { VehicleData } from "@/model/Vehicle";
import { Options, Vue } from "vue-class-component";
import { Prop }         from "vue-property-decorator";

export declare class IPriceAndCost {
  $props: {
      vehicleData : VehicleData;
      priceLabel? : string;
      costLabel?  : string;
      canDisable? : boolean;
      showPrice?  : boolean;
      showCost?   : boolean;
  };

  $emit(eventName: 'removeVehicle', value: VehicleData): any;
}

@Options({})
export default class PriceAndCost extends Vue {

  @Prop()
  readonly vehicleData!: VehicleData;
  
  @Prop({ default: "Prezzo extra" }) 
  readonly priceLabel!: string;
  
  @Prop({ default: true })
  readonly showPrice! : boolean;
  
  @Prop({ default: "Costo extra" })  
  readonly costLabel!: string;

  @Prop({ default: true })
  readonly showCost!  : boolean;
  
  @Prop({default: true}) 
  readonly canDisable!: boolean;

  get icon(): string{
    return this.vehicleData.vehicle.icon;
  }

  get title(): string{
    return this.vehicleData.vehicle.name_it; 
  }

  get enabled(){
    return this.vehicleData.is_enabled;
  }

  set enabled(value: boolean){
    this.vehicleData.is_enabled = value;
  }

  get hasRemoveHandler(){
    return !!this.$attrs?.onRemoveVehicle;
  }

  removeVehicle(){
    console.debug("this.$props",this.$props);
    console.debug("this.$attrs",this.$attrs);
    this.$emit('removeVehicle', this.vehicleData);
  }
}