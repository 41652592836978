import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "week_nav" }
const _hoisted_2 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_Button, {
        icon: "pi pi-chevron-left",
        class: "p-button-info",
        onClick: _ctx.previousWeek,
        disabled: _ctx.disabled
      }, null, 8, ["onClick", "disabled"]), [
        [_directive_tooltip, _ctx.$t('previous_week')]
      ]),
      _createVNode(_component_InputText, {
        readonly: "",
        value: _ctx.formattedRange,
        style: {textAlign: 'center', width: '250px'}
      }, null, 8, ["value"]),
      _withDirectives(_createVNode(_component_Button, {
        icon: "pi pi-chevron-right",
        class: "p-button-info",
        onClick: _ctx.nextWeek,
        disabled: _ctx.disabled
      }, null, 8, ["onClick", "disabled"]), [
        [_directive_tooltip, _ctx.$t('next_week')]
      ])
    ])
  ]))
}