export const CUSTOMER_VALIDATION_SCHEMA = {
    name   : 'required',
    surname: 'required',

    business_name: 'required',
    business_tel : 'required',
    email        : 'required|email',
    pec          : 'required|email',

    business_sdi: null,

    vat        : 'requiredThisOr:fiscal_code',
    fiscal_code: 'requiredThisOr:vat',

    business_city_name  : 'required',
    business_address    : 'required',
    business_postal_code: 'required',
    business_province   : null,
    business_state      : null,

    sdi: null,

    is_active          : null,
    perc_fee           : null,
    cost_avg_delivery  : null,
    is_time_cost_active: null,
    is_ignore_cost_zone: null,
    is_perc_fee_active : null,
}
