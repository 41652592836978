
import { useField }                         from "vee-validate"
import { computed, defineComponent, toRef } from "vue";

export default defineComponent( {
  inheritAttrs: false,

  props: [
    'field',
    'label',
    'hideError'
  ],

  setup( p ) {
    const field = toRef( p, 'field' );

    const isValid = computed( () => {
      return (meta.dirty) && meta.valid;
    } )

    const {
            value,
            errors,
            meta,
            setTouched
          } = useField( field, undefined, {
      initialValue: ''
    } )

    return {
      isValid,
      value,
      errors,
      setTouched,
      meta
    }
  }
} )

