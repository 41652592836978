import { Customer } from '@/model/Customer';
import { Vehicle } from '@/model/Vehicle';
import { CRUD }     from './base/crud';

class CustomersService extends CRUD<Customer, any> {
    readonly endPoint = `admin/customers`;


    public getAvailableVehicles(customerId: number, zone_id: number){
        const params = { zone_id };
        return this.get<Vehicle[]>(`${this.endPoint}/${customerId}/available-vehicles`, {params});
    }
}

export const customersService = new CustomersService();



