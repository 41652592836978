
import { Options, Vue }  from "vue-class-component";
import { Prop }          from "vue-property-decorator";

import {
  PriceAndCost,
  VehicleAutocomplete
} from "@/components";

import { Vehicle, VehicleData } from "@/model/Vehicle";
import { Entity }               from "@/model/Entity";
import { Zone, ZoneRelation }   from "@/model/Zone";

export declare class IZoneVehicleData {
    $props: {
      entity     : Entity;
      zone       : Zone | ZoneRelation;
      showPrice? : boolean;
      showCost?  : boolean;
    };
}

@Options({
  components: {
    PriceAndCost,
    VehicleAutocomplete
  }
})
export default class ZoneVehicleData extends Vue {
  @Prop() 
  readonly entity! : Entity;

  @Prop() 
  readonly zone!   : Zone;
  
  @Prop({ default: true })
  readonly showPrice! : boolean;

  @Prop({ default: true })
  readonly showCost!  : boolean;

  get vehicles(){
    return this.entity.vehicle_data
      ?.filter( x => x.zone_id === this.zone.id);
  }

  get selectedVehicleIds(){
    return this.vehicles
      ?.filter( x => x.zone_id === this.zone.id )
      ?.map( x => x.vehicle_id ) || [];
  }

  get hasVehicles() {
    return !!this.vehicles?.length;
  }

  onSelect(value: Vehicle) {
    this.entity.vehicle_data = [
      ...(this.entity.vehicle_data || []),
      {
        id          : null,
        extra_cost  : null,
        extra_price : null,
        is_enabled  : false,
        vehicle_id  : value.id,
        zone_id     : this.zone.id,
        entity_id   : this.entity.id,
        vehicle     : value
      }
    ];
  }

  removeVehicle(value: VehicleData){
    const idx =this.entity.vehicle_data.indexOf(value);
    this.entity.vehicle_data.splice(idx, 1);
  }
}