import { User } from '@/model/User';
import { CRUD } from './base/crud';

class UsersService extends CRUD<User, any> {
    readonly endPoint = `admin/users`;
}

export const usersService = new UsersService();



