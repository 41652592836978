import { BaseApiFilter } from '@/model/filters/BaseApiFilter';
import { AvailableTaker, Taker, TakerAvailability } from '@/model/Taker';
import { CRUD } from './base/crud';


export class TakerApiFilter extends BaseApiFilter {
    with_vehicle?: boolean = false;
    
    constructor(data?: TakerApiFilter) {
        super(data);
        
        if (data) {
            Object.assign(this, data);
        }
    }
}

export interface OnlyAvailableParams {
    zone_id?  : number;
    shift_id? : number;

    /**
     * Date format: YYYY-MM-DD
     */
    date: string;
}


class TakersService extends CRUD<Taker, TakerApiFilter> {
    readonly endPoint = `admin/takers`;

    public index(params: TakerApiFilter) {
        params ??= new TakerApiFilter(); 
        params.with_vehicle = true; 
        
        return super.index(params);
    }

    public getById(id: number, params?: OnlyAvailableParams): Promise<Taker>{
        return this.get<Taker>(`${this.endPoint}/${id}`, { params});
    }

    public shiftAvailabilities(
        takerId   : number,
        date_from : string,
        date_to   : string
    ) {
        return this.get<TakerAvailability[]>(
            `${this.endPoint}/${takerId}/shift-availabilities`,
            {
                params: {
                    date_from, date_to
                }
            }
        );
    }

    public onlyAvailableIn(params: OnlyAvailableParams) {
        return this.get<AvailableTaker[]>(
            `${this.endPoint}/available`, { params }
        );
    }
}

export const takersService = new TakersService();



