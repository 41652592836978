import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "left"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("header", null, [
    (!_ctx.noBack)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "left", {}, () => [
            _createVNode(_component_BackButton, { to: _ctx.backRoute }, null, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "title", {}, () => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        (_ctx.subTitle)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.subTitle), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "actions", {}, () => [
        _renderSlot(_ctx.$slots, "leftActions"),
        _renderSlot(_ctx.$slots, "saveButton", {}, () => [
          (_ctx.showSave)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                disabled: _ctx.saveDisabled,
                icon: "pi pi-save",
                class: "p-mr-1",
                onClick: _ctx.onSave,
                isLoading: _ctx.requestPending
              }, null, 8, ["disabled", "onClick", "isLoading"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.save'),
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true)
        ]),
        (_ctx.showDelete)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 0,
              disabled: _ctx.deleteDisabled,
              icon: "pi pi-trash",
              class: "p-button-danger",
              onClick: _ctx.onDelete
            }, null, 8, ["disabled", "onClick"])), [
              [_directive_tooltip, _ctx.$t('delete')]
            ])
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}