import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, _mergeProps(_ctx.$attrs, {
    completeOnFocus: "",
    dropdown: "",
    modelValue: _ctx.searchBox,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchBox = $event)),
    suggestions: _ctx.suggestions,
    field: "name",
    placeholder: "Cerca punto di ritiro",
    onItemSelect: _ctx.onSelect,
    onComplete: _ctx.search,
    onChange: _ctx.onChange
  }), {
    item: _withCtx(({ item }) => [
      _createElementVNode("strong", null, _toDisplayString(item.name), 1),
      (item.address)
        ? (_openBlock(), _createElementBlock("i", _hoisted_1, "- " + _toDisplayString(item.address), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["modelValue", "suggestions", "onItemSelect", "onComplete", "onChange"]))
}