
import { mapStyles } from "@/utils/gmap_utils";
import { configuration } from "@plugins/Config-plugin";
import { ref, toRaw, computed } from "vue";
import { useGoogleLoader } from "./useGoogleLoader";

type Map = google.maps.Map;

export interface GmapConfig {
    center    : { lat: number, lng: number };
    zoom      : number;
    language? : string;
}

export function useGoogleMap( gmapConfig?: GmapConfig) {
    const { center, zoom, language } = gmapConfig || {
        center : configuration.startCenter,
        zoom   : configuration.startZoom
    };

    const { loadGoogle } = useGoogleLoader(language || 'it');

    const mapRef = ref<google.maps.Map>(null);

    const map = computed( () => toRaw(mapRef.value) );

    async function buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {

        mapRef.value = new google.maps.Map(
            element,
            { 
                center, 
                zoom,  
                mapTypeControl: false,
                styles : mapStyles
            },
            
        );

        if( bounds ) {
            map.value.fitBounds(bounds, 0);
        }

        map.value.data.setStyle({
            strokeWeight  : 1,
            strokeOpacity : 1,
            strokeColor   : "#3399FF",
            fillColor     : "#3399FF",
            fillOpacity   : 0.2,
            editable      : true,
            draggable     : true,
            clickable     : true,
            zIndex        : 1,
        });

        return mapRef.value;
    }

    function centerOn(location: google.maps.LatLng){
        map.value.setZoom(16);
        map.value.setCenter(location);
    }

    function clearMap(map: Map){
        map.data.forEach((f) => {
            map.data.remove(f);
        });
    }

    return {
        map,
        mapRef,
        loadGoogle,
        buildMap,
        centerOn,
        clearMap,
    };
}