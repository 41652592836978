
import { defineComponent, ref } from 'vue';
import SendMessageDialog        from "@components/SendMessageDialog/SendMessageDialog.vue";

export default defineComponent( {
  name      : "ButtonSendMessage",
  components: { SendMessageDialog },
  props     : [ "userId" ],
  setup() {
    const displayMessageDialog = ref( false );

    function showMessageDialog() {
      displayMessageDialog.value = true;
    }

    return {
      displayMessageDialog,
      showMessageDialog
    }
  }
} )
