
import { useField } from "vee-validate";
import { defineComponent, toRefs } from "vue";

interface VInputSwitchProps {
  name?: string; 
  labelOn?: string; 
  labelOff?: string; 
}

export default defineComponent({
  props: ['name', 'labelOn', 'labelOff'],
  setup(props: VInputSwitchProps) {
    const { name } = toRefs(props);

    const {
      value,
      errorMessage,
    } = useField<boolean>(name, undefined, {
      type: 'checkbox',
      checkedValue: true,
      uncheckedValue: false,
    });
    
    return {
      value, 
      errorMessage,
    }

  }
})
