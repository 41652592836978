import { defineComponent, toRefs, watch } from "vue";
import { useAutocompleteWithParams }      from "@/use/useAutocomplete";
import { FilterMatchMode }  from "primevue/api";
import { usersService }     from "@services/users.service";
import { User }             from "@/model/User";


export default defineComponent({
  name: 'UserAutocomplete',

  inheritAttrs: false,

  props: ['modelValue'],

  emits: ['resultSelected'],

  setup(_, { emit }) {
    const { modelValue } = toRefs(_);

    const {
      onSelect,
      search,
      searchBox,
      suggestions,
    } = useAutocompleteWithParams<User>({
      service: usersService,
      params: {
        is_active: {
          value: true,
          matchMode: FilterMatchMode.EQUALS
        }
      },
      afterSelect: (user: User) => {
        emit('resultSelected', user);
      }
    });

    function onChange(e: Event) {
      const v = (e.target as HTMLInputElement).value;
      if (!v) {
        emit('resultSelected', null);
      }
    }

    function fieldFn(data: User) {
      return `${data.name} ${data.surname}`;
    }

    watch(
      modelValue,
      () => {
        searchBox.value = modelValue?.value;
      },
      {
        immediate: true
      }
    );

    return {
      searchBox,
      suggestions,

      onSelect,
      onChange,
      search,
      fieldFn
    }
  }
})