
  import { defineComponent }        from "vue";
  import { ErrorMessage as VError } from "vee-validate";

  export default defineComponent({
    inheritAttrs: false,
    components: {
      VError
    },
  });
