import { Options, Vue } from "vue-class-component";
import { Prop, Watch }  from "vue-property-decorator";

import { Shift }           from "@/model/Shift";
import { EntityWithCosts } from "@/model/Entity";
import { WeekShiftCosts }  from "@/model/ShiftCost";
import { Zone }            from "@/model/Zone";

import {
  TimeShiftAutocomplete
} from "@/components"

@Options({
  components: {
    TimeShiftAutocomplete
  }
})
export default class ZoneShiftCosts extends Vue {
  @Prop() readonly entity! : EntityWithCosts;
  @Prop() readonly zone!   : Zone;

  tableShiftCosts: WeekShiftCosts[] = null;

  get hasCustomShiftCosts() {
    return !!this.tableShiftCosts?.length;
  }

  get shiftCosts() {
    return this.entity?.shift_costs;
  }

  get selectedShiftsIds() {
    return this.shiftCosts
      ?.filter(x => x.zone_id === this.zone.id)
      ?.map(x => x.shift_id)
      ?.reduce((acc, x) => {
        if (!acc?.includes(x)) { acc.push(x) }
        return acc;
      }, []);
  }

  @Watch('shiftCosts', {immediate: true})
  onShiftCostsChange() {
    const groupedByShift = this.entity?.shift_costs
      // Le fasce orarie della città corrente 
      ?.filter(s => s.zone_id === this.zone?.id)
      ?.map(s => {
        const { id, ...other } = s;

        return {
          ...other,
          shift_cost_id: id
        }
      })
      ?.groupBy('shift_id');

    const result: WeekShiftCosts[] = [];
    groupedByShift?.forEach((a, k) => {
      /*
        Ogni gruppo raccoglie al massimo 7 valori, 
        uno per ciascun giorno della settimana.
        Perciò mantenendo le informazioni della fascia,
        creo un nuovo oggetto con le 7 giornate 
        assegnate a specifici campi. "weekDay[numero]"
      */
      const row = a?.reduce((acc, item) => {
        acc = {
          ...acc,
          zone_id: item.zone_id,
          shift: item.shift,
          [`weekDay${item.weekday}`]: item
        }

        return acc;
      }, {} as WeekShiftCosts)

      result.push(row);
    });

    this.tableShiftCosts = result;
  }
  
  onTimeShiftSelect(data: Shift) {
    this.entity.shift_costs = [
      ...(this.entity.shift_costs || []),
      ...[1, 2, 3, 4, 5, 6, 7].map((x) => ({
        shift_id  : data.id,
        shift     : data,
        zone_id   : this.zone.id,
        entity_id : this.entity.id,
        hour_cost : 0,
        weekday   : x
      }))
    ]
  }

  onWeekDayCostChange(days: number[], data: WeekShiftCosts, value: number) {
    const { shift, zone_id } = data;
    const shiftId = data.shift.id;

    days.forEach(wd => {
      const wdShift = this.entity.shift_costs.find(x => 
           x.weekday  === wd      // Giorno della settimana
        && x.shift_id === shiftId // ID Fascia oraria
        && x.zone_id  === zone_id // ID città 
      );
      
      if (wdShift) {
        wdShift.hour_cost = value; 
        data[`weekDay${wd}`].hour_cost = value; 
      } else {
        this.entity.shift_costs = [
          ...(this.entity.shift_costs || []),
          {
            shift     : shift,
            shift_id  : shiftId,
            zone_id   : this.zone.id,
            entity_id : this.entity.id,
            hour_cost : value,
            weekday   : wd
          }
        ]
      }
    });
  }

  removeShift({ shift, zone_id }: WeekShiftCosts) {
    this.entity.shift_costs = this.entity.shift_costs
      .filter(s => (
           s.shift_id !== shift.id 
        || s.zone_id  !== zone_id
      ));
  }
}