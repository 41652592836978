import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    minLength: 2,
    completeOnFocus: "",
    field: "name",
    suggestions: _ctx.suggestions,
    onComplete: _ctx.onComplete,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, null, 8, ["suggestions", "onComplete", "modelValue"]))
}