import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_VI18nError = _resolveComponent("VI18nError")!
  const _component_VField = _resolveComponent("VField")!

  return (_openBlock(), _createBlock(_component_VField, { name: _ctx.name }, {
    default: _withCtx(({
        value,
        handleChange,
        errors,
        meta: { valid, touched }
    }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FloatLabel, {
          for: _ctx.id,
          label: _ctx.label + (_ctx.isRequired ? ' *' : '')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Dropdown, {
              id: _ctx.id,
              options: _ctx.options,
              optionLabel: "label",
              optionValue: "value",
              class: _normalizeClass({
            'p-valid' : valid,
            'p-invalid' : (touched && errors?.length)
          }),
              modelValue: value,
              onChange: ($event: any) => (_ctx.onChange($event.value, handleChange))
            }, null, 8, ["id", "options", "class", "modelValue", "onChange"])
          ]),
          _: 2
        }, 1032, ["for", "label"]),
        touched
          ? (_openBlock(), _createBlock(_component_VI18nError, {
              key: 0,
              name: _ctx.name
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}