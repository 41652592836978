import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FloatLabel, { label: _ctx.label }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, _mergeProps({
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = $event)),
          class: {
          'p-invalid': (_ctx.meta.touched) && _ctx.errors?.length,
          'p-valid': _ctx.isValid
        }
        }, _ctx.$attrs, {
          onFocusin: _cache[1] || (_cache[1] = () => _ctx.setTouched(true))
        }), null, 16, ["modelValue", "class"])
      ]),
      _: 1
    }, 8, ["label"]),
    ((_ctx.meta.touched) && _ctx.errors)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.errors, (e) => {
          return (_openBlock(), _createElementBlock("p", {
            key: e,
            class: "p-error"
          }, _toDisplayString(_ctx.$t(e)), 1))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}