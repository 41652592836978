
import { useField } from "vee-validate";
import { defineComponent, toRefs } from "vue";

interface VInputSwitchProps {
  field?: string; 
  label?: string; 
}

export default defineComponent({
  props: ['field', 'label', 'id'],
  setup(props: VInputSwitchProps) {
    const { field } = toRefs(props);

    const {
      value,
      errorMessage,
    } = useField<boolean>(field, undefined, {
      type: 'checkbox',
      checkedValue: true,
      uncheckedValue: false,
    });
    
    return {
      value, 
      errorMessage,
    }

  }
})
