import { Options, Vue }     from "vue-class-component";
import { Prop }             from "vue-property-decorator";
import { RouteLocationRaw } from "vue-router";
import {OrdersRoutesEnum} from "@/modules/orders/router";

export declare class IPageHeader{
  $props: {
    title           : String;
    saveDisabled?   : Boolean;
    deleteDisabled? : Boolean;
    showSave?       : Boolean;
    showDelete?     : Boolean;
    noBack?         : Boolean;
    backRoute?      : RouteLocationRaw;
  }

  $emit(eventName: 'onSave')   : any;
  $emit(eventName: 'onDelete') : any;
}

@Options({
  emits: ['onSave', 'onDelete']
})
export default class PageHeader extends Vue {
  @Prop()
  readonly title!: String;

  @Prop()
  readonly subTitle!: String;

  @Prop()
  readonly saveDisabled!: Boolean;

  @Prop()
  readonly deleteDisabled!: Boolean;

  @Prop({default: true})
  readonly showDelete!: Boolean;

  @Prop({default: true})
  readonly showSave!: Boolean;

  @Prop({ default: false })
  readonly noBack!: Boolean;

  @Prop({
    default: () => {
      return { name: OrdersRoutesEnum.ORDERS_LIST }
    }
  })
  readonly backRoute!: RouteLocationRaw;

  onBack(){
    if(this.backRoute){
      this.$router.replace(this.backRoute);
    }else{
      this.$router.back();
    }
  }
  onSave(){
    this.$emit('onSave');
  }

  onDelete(){
    this.$emit('onDelete');
  }
}
