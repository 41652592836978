import { Supplier } from '@/model/Supplier';
import { CRUD } from './base/crud';

class SuppliersService extends CRUD<Supplier, any> {
    readonly endPoint = `admin/suppliers`;
}

export const suppliersService = new SuppliersService();



