import { EntityPicker }         from '@/model/Entity';
import { EntityPickersFilter }  from '@/model/filters/EntityPickersFilter';
import { CRUD }                 from './base/crud';

class EntityPickers extends CRUD<EntityPicker, EntityPickersFilter> {
    readonly endPoint = `admin/entity-pickers`;
}

export const entityPickersService = new EntityPickers();



