import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    completeOnFocus: "",
    modelValue: _ctx.searchBox,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchBox = $event)),
    suggestions: _ctx.suggestions,
    field: "name_it",
    placeholder: "Cerca e aggiungi tipologia",
    onItemSelect: _ctx.onSelect,
    onComplete: _ctx.search
  }, {
    item: _withCtx(({item}) => [
      _createElementVNode("i", {
        class: _normalizeClass(`fas fa-${item.icon} p-mr-2`)
      }, null, 2),
      _createElementVNode("span", null, _toDisplayString(item.name_it), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "suggestions", "onItemSelect", "onComplete"]))
}