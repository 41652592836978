import { CRUD }          from './base/crud';
import { UserRoleEnum }  from "@/model/User";
import { IBaseApiModel } from "@/model/common/BaseApiModel";

export interface EntityUser extends IBaseApiModel {
    entity_id: number;
    name: string;
    surname: string;
    email: string;
    role: UserRoleEnum;
    is_active: boolean;
    password: string;
}

class EntityUsersService extends CRUD<EntityUser, any> {
    readonly endPoint = `admin/entity-users`;
}

export const entityUsersService = new EntityUsersService();



