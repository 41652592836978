import { User, UserRoleEnum }         from "@/model/User";
import { Options, Vue }               from "vue-class-component";
import { Model, Prop }                from "vue-property-decorator";
import UserForm                       from "@components/UserForm/UserForm.vue";
import { DropdownOptions }            from "@/model/DropdownOption";

@Options({
  components: {
    UserForm
  },
  emits: [
    'dialogSave',
    'dialogClose'
  ]
})
export default class UserDialog extends Vue {

  @Model('show')
  readonly showModel!: boolean; 

  @Model('user') 
  readonly userModel!: User;
  
  @Prop({ default() { return [] } })
  readonly roleOptions!: DropdownOptions<UserRoleEnum>;

  private close(){
    this.$emit('update:show', false);
  }

  onDialogCancel(){
    this.close();
    this.$emit('dialogClose');
  }

  async onDialogSave(value){
    this.close();
    this.$emit('dialogSave', value);
  }
}