<template>
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-search" />
    </span>

    <AutoComplete
      completeOnFocus
      :multiple="multiple"
      v-model="selectedZones"
      :suggestions="zoneSuggestions"
      @complete="searchZone"
      field="name"
      :minLength="2"
      :placeholder="$t('zone_autocomplete.placeholder')"
    />

    <Button
      icon="pi pi-plus"
      :label="$t('add')"
      :disabled="isButtonDisabled"
      v-tooltip.bottom="$t('add')"
      @click="emit"
    />
  </div>
</template>

<script lang="js">
  import { 
    defineComponent, 
    computed, 
    ref, 
    toRefs 
  } from "vue";

  import { FilterMatchMode } from "primevue/api";

  import { BaseApiFilter }        from "@/model/filters/BaseApiFilter";
  import { zonesService }         from "@services/zones.service";

  export default defineComponent({
    inheritAttrs: false,

    props: {
      selectedIds: Array,
      multiple: {
        type: Boolean,
        default: true,
      }
    },  

    emits: [ 
      'selectedZones' 
    ],

    setup(props) {
      const { multiple, selectedIds } = toRefs(props);
      
      let selectedZones     = ref(null);
      let zoneSuggestions   = ref(null);

      async function searchZone({ query }) {
        const f = new BaseApiFilter();
        f.q = query;

        f.filters = {
          is_active: {
            value: true, 
            matchMode: FilterMatchMode.EQUALS
          }
        }

        const response = await zonesService.index(f);
        zoneSuggestions.value = (response.data?.filter(notSelected));
      }

      function notSelected(x) {
        return !(selectedIds.value || []).includes(x.id);
      }

      const isButtonDisabled = computed(() => 
        (multiple.value   && !selectedZones.value?.length)  ||  
        (!multiple.value  && !selectedZones.value)
      );

      return {
        selectedZones,
        zoneSuggestions,
        searchZone,
        isButtonDisabled
      }
    },

    methods: {
        emit(){
            this.$emit('selectedZones', this.selectedZones);
            this.selectedZones = null; 
        }
    }
  });
</script>