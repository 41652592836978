import { defineComponent, toRefs, watch } from "vue";

import { Supplier }         from "@/model/Supplier";
import { useAutocomplete }  from "@/use/useAutocomplete";
import { suppliersService } from "@services/suppliers.service";

export default defineComponent( {
    inheritAttrs: false,

    props: [ 'modelValue' ],

    emits: [
        'resultSelected',
        'update:modelValue'
    ],

    setup( _, { emit } ) {
        const { modelValue } = toRefs( _ );

        const {
                  onSelect,
                  search,
                  searchBox,
                  suggestions,
              } = useAutocomplete<Supplier>(
            suppliersService,
            ( supplier: Supplier ) => {
                emit( 'update:modelValue', supplier );
                emit( 'resultSelected', supplier );
            }
        );

        function onChange( e: Event ) {
            const v = (e.target as HTMLInputElement).value;
            if (!v) {
                emit( 'update:modelValue', null );
                emit( 'resultSelected', null );
            }
        }

        watch(
            modelValue,
            () => {
                searchBox.value = modelValue.value;
            },
            {
                immediate: true
            }
        );

        function clear() {
            searchBox.value = null;
            emit( 'update:modelValue', null );
            emit( 'resultSelected', null );
        }

        return {
            searchBox,
            suggestions,

            onSelect,
            onChange,
            search,
            clear,
        }
    }
} )
