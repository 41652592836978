import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  inheritAttrs: false,
})
export default class TimeslotSelect extends Vue {

  @Prop({ default: false })
  readonly noPastSlots: boolean;


  get timeslots() {
    return this.noPastSlots 
      ? this.$store.getters.timeslotsLabelValue?.filter(t => {
        const [h, m, s] = t.endTime.split(':');

        const slotDate = new Date();
        slotDate.setHours(h, m, s);

        return Date.now() < slotDate.getTime() 
      })
      : this.$store.getters.timeslotsLabelValue;
  }

  created() {
    if (!this.$store.getters.timeslotsLabelValue?.length) {

      this.$waitFor( async () => {
        await this.$store.dispatch('loadShifts')
      });
    }
  }
}