 
import { Order } from "@/model/Order";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: ['data'],
  setup(props: {data: Order}) {
    const pickupPriority  = computed(() => props.data.pickup_priority)
    const pickupDateTime  = computed(() => props.data.pickup_datetime)
    const dropoffPriority = computed(() => props.data.dropoff_priority)
    const dropoffDateTime = computed(() => props.data.dropoff_datetime)

    return {
      pickupPriority,
      pickupDateTime,
      dropoffPriority,
      dropoffDateTime,
    }

  }
})
