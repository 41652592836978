import { Options, Vue }     from "vue-class-component";
import { Prop }             from "vue-property-decorator";

import { Entity, UserType } from "@/model/Entity";
import { PaymentTypeEnum }  from "@/model/enums/PaymentTypeEnum";

import { domainService }    from "@services/domain.service";
import { BaseApiFilter }    from "@/model/filters/BaseApiFilter";

import { AddressAutocomplete } from "@/components";

export declare class IEntityForm {
  $props: {
    isLoading?: boolean;
    showIsActive?: boolean;
    showPayment?: boolean;
    showNote?: boolean;
    modelValue?: Entity;
  }
}

@Options({
  inheritAttrs: false,
  components: {
    AddressAutocomplete,
  }
})
export default class EntityForm extends Vue {
  @Prop({ default: false })
  readonly isLoading!: boolean;

  @Prop({ default: false })
  readonly hideUserData!: boolean;

  @Prop({ default: true })
  readonly showPayment!: boolean;

  @Prop({ default: true })
  readonly showCosts!: boolean;

  @Prop({ default: true })
  readonly showNote!: boolean;

  @Prop({ default: true })
  readonly showIsActive!: boolean;

  @Prop({ default() { return {} } })
  readonly modelValue!: Entity;

  citiesSuggestions: any[] = [];

  get entityIsCustomer() {
    return this.modelValue?.type === UserType.CUSTOMER;
  }

  get paymentOptions() {
    return [
      {
        label: this.$t('payment_type.anticipated'),
        value: PaymentTypeEnum.ANTICIPATED
      },
      {
        label: this.$t('payment_type.postponed'),
        value: PaymentTypeEnum.POSTPONED
      },
    ];
  }

  get businessAddress() {
    return (this.modelValue.business_address as any)?.description
      || this.modelValue.business_address;
  }

  set businessAddress(v) {
    this.modelValue.business_address = v; 
  }

  onSelectCity(value) {
    this.modelValue.business_city_id = value.id;
    this.modelValue.business_city_name = value.name;
  }

  async searchCity({ query }) {
    const response = await domainService.cities(
      BaseApiFilter.fromGlobal(
        query,
        {
          sortField: 'name',
          sortOrder: 1
        }
      )
    );

    const q = query.toLowerCase();

    /**
     * Metto in evidenza le città che iniziano con la stringa inserita
     */
    response.data.sort( (a,b) => {
      const a_StartsWithQ = a.name.toLowerCase().startsWith(q);
      const b_StartsWithQ = b.name.toLowerCase().startsWith(q);

      if (a_StartsWithQ && !b_StartsWithQ) {
        return -1; 
      } else if (!a_StartsWithQ && b_StartsWithQ) {
        return 1; 
      } else {
        return a.name.localeCompare(b.name)
      } 
    })

    this.citiesSuggestions = [...response.data];
  }

  setCity(city) {
    this.modelValue.business_city      = city        || null;
    this.modelValue.business_city_id   = city?.id    || null;
    this.modelValue.business_city_name = city?.name  || null;
  }

  handleCityChange(event, callback) {
    callback(event);
  }

  geocodeResult: google.maps.GeocoderResult = null;
  onSelectAddress(item: google.maps.GeocoderResult) {
    this.geocodeResult = item;

    const province   = this.getProvince(item);
    const postalCode = this.getPostalCode(item);
    const state      = this.getState(item);

    this.modelValue.business_postal_code = postalCode;
    this.modelValue.business_province    = province;
    this.modelValue.business_state       = state;
  }

  getState(g: google.maps.GeocoderResult) {
    return g.address_components
      .find(x => x.types?.includes("country"))
      ?.long_name;
  }

  getProvince(g: google.maps.GeocoderResult) {
    return g.address_components
      .find(x => x.types?.includes("administrative_area_level_2"))
      ?.short_name;
  }

  getPostalCode(g: google.maps.GeocoderResult) {
    return g.address_components
      .find(x => x.types?.includes("postal_code"))
      ?.short_name;
  }
}