import { Zone }         from '@/model/Zone';
import { plainToClass } from 'class-transformer';
import { CRUD }         from './base/crud';

class ZonesService extends CRUD<Zone, any> {
    readonly endPoint = `admin/zones`;

    public getById(id: number): Promise<Zone> {
        return this.view(id, true);
    }

    public getByIdNoShapes(id: number): Promise<Zone> {
        return this.view(id, false);
    }

    private view(id: number, withShapes: boolean){
        let url = `${this.endPoint}/${id}`; 

        if(withShapes){
            url += `?with_shapes=true`
        }

        return this.get<Zone>(url)
            .then(response => plainToClass(Zone, response));
    }
}

export const zonesService = new ZonesService();



