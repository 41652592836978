import { ref }  from "vue";
import { Path } from "@/model/Zone";

import booleanContains  from "@turf/boolean-contains";

import {
    polygon as createPolygon, 
    point   as createPoint
} from "@turf/helpers";

export function checkPointInShape(
    latLng  : google.maps.LatLng,
    shape   : Path
){
    const point = createPoint([
        latLng.lng(), 
        latLng.lat()
    ]);

    const polygon = createPolygon([shape]);

    return booleanContains(polygon, point);
}

export function useIsServed(){
    const isServed = ref<boolean>(null);

    function updateIsServed(
        latLng  : google.maps.LatLng,
        shape   : Path
    ){
        isServed.value = checkPointInShape(latLng, shape);

        return isServed.value;
    }

    return {
        isServed,
        checkPointInShape,
        updateIsServed
    }
}