import { ShapeInfo } from "@/model/Zone";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export declare class IZoneShapesTable {
  $props: { shapes: ShapeInfo[] };
}

@Options({})
export default class ZoneShapesTable extends Vue {
  @Prop() readonly shapes!: ShapeInfo[];

  readonly colors = [
    "#00ffb7",
    "#33ff00",
    "#a8d600",
    "#ff9100",
    "#ff0000"
  ];
}