import axios, { CancelTokenSource } from "axios";
import { HttpService }              from './base/http.service';

import { City }                     from "@/model/City";
import { BaseApiFilter }            from "@/model/filters/BaseApiFilter";
import { PaginatedResponse }        from "@/model/PaginatedResponse";

class DomainService extends HttpService {

    private readonly endPoint = ``;
    
    protected source: CancelTokenSource;

    public cities(params: any) {
        if (!params){
            params = (new BaseApiFilter());
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        return this.get<PaginatedResponse<City>>(
            `${this.endPoint}/cities`, 
            { 
                params,
                cancelToken
            }
        );
    }

    public provinces(params: any) {
        if (!params){
            params = (new BaseApiFilter());
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        return this.get<PaginatedResponse<City>>(
            `${this.endPoint}/cities`, 
            { 
                params,
                cancelToken
            }
        );
    }
}

export const domainService = new DomainService();



