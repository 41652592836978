import { 
    ref, 
    toRaw, 
    PropType, 
    computed, 
    onMounted, 
    defineComponent, 
} from "vue";

import { useGoogleMap } from "@/use/googleMaps";
import { useIsServed }  from "@/use/useIsServed";

import { entityPickersService } from "@services/entityPickers.service";

import {
    EntityPickerType,
    EntityPicker
} from "@/model/Entity";

import { 
    IZone, 
    Zone, 
    Path 
} from "@/model/Zone";

import { AddressAutocomplete, AppMap } from "@/components";

export default defineComponent({
    props: {
        entityId: Number, 

        pickerType: Number as PropType<EntityPickerType>,

        initialValue: EntityPicker,

        zone: Object as PropType<Zone>,

        emitOnly: {
            type: Boolean,
            default: false
        }
    },

    components: {
        AddressAutocomplete,
        AppMap
    },

    setup(props) {
        const msg = ref<string>('Indirizzo esterno alla città selezionata');
        const errorOnSave = ref<boolean>(false);

        const title = computed(
            () => type.value === EntityPickerType.DROPOFF 
                ? "Punto di Consegna" 
                : "Punto di Ritiro"
        );

        const isDropoff = computed(
            () => type.value === EntityPickerType.DROPOFF 
        );

        const isPickup = computed(
            () => type.value === EntityPickerType.PICKUP 
        );

        const picker              = ref(new EntityPicker());
        const autocompleteAddress = ref<string>('');
        
        const geocodeResult = ref<google.maps.GeocoderResult>(null);

        let marker: google.maps.Marker         = null;
        let infowindow: google.maps.InfoWindow = null;

        const shape = ref(null);

        const {
            isServed, 
            updateIsServed
        } = useIsServed();
        
        const {
            map,
            mapRef,
            loadGoogle,
            buildMap,
            centerOn
        } = useGoogleMap();


        const type        = ref<EntityPickerType>(EntityPickerType.PICKUP);

        function getLastShapeFromZone(zone: IZone) {
            let last;

            for (let i = 5; i >= 1; i--) {
                const s: Path = zone[`shape${i}`];
                const active =  zone[`is_shape${i}_active`]

                if (s?.length && active) {
                    last = s;
                    break;
                }
            }

            return last;
        
        }

        function drawZoneAndBound(coordinates){
    
            const paths = coordinates
                    .map(([lng, lat]) => new google.maps.LatLng(lat, lng));

            new google.maps.Polygon({
                map: toRaw(mapRef.value),
                paths,

                strokeColor   : "00ffb7",
                strokeOpacity : 0.8,
                strokeWeight  : 2,
                fillColor     : "00ffb7",
                fillOpacity   : 0.35,
            });

            const bounds = new google.maps.LatLngBounds();
            
            paths.forEach((x) => {
                bounds.extend(x)
            });
            
            mapRef.value.fitBounds(bounds);
        }

        function _updatePickerLocation(geocoder: google.maps.GeocoderResult) {
            picker.value.address     = geocoder.formatted_address;
            picker.value.lat         = geocoder?.geometry?.location.lat();
            picker.value.lon         = geocoder?.geometry?.location.lng();
            picker.value.city_name   = getCityName(geocoder);
            picker.value.postal_code = getPostalCode(geocoder);
            picker.value.province    = getProvince(geocoder);

        }


        function onSelectAddress(item: google.maps.GeocoderResult) {
            geocodeResult.value = item;

            _updatePickerLocation(item);

            drawMarkerAndInfowindow(
                item.geometry.location, 
                item.formatted_address
            );

            centerOn(item.geometry.location);

            if ( props.zone ){
                updateIsServed(item.geometry.location, toRaw(shape.value));
            }
        }

        function drawMarkerAndInfowindow(location, infoWindowText){
            marker.setMap(map.value);
            marker.setPosition(location);

            infowindow.setContent(infoWindowText);
            infowindow.open(map.value, marker);
        }

        
        async function init(initialValue: EntityPicker) {
            await loadGoogle();

            mapRef.value = await buildMap(
                document.getElementById('pickerMap'),
            );

            marker = new google.maps.Marker({
                draggable: true,
            });

            marker.addListener(
                "dragend", 
                (e: google.maps.MapMouseEvent) => {
                    geocodeResult.value.geometry.location = e.latLng;
                } 
            );

            infowindow = new google.maps.InfoWindow();

            if (initialValue) { 
                // UPDATE
                picker.value = new EntityPicker(initialValue);                

                const { lat, lon } = initialValue;
                const location = new google.maps.LatLng(lat, lon);

                drawMarkerAndInfowindow(
                    location, 
                    picker.value.address
                );

                centerOn(location);
            } else { 
                // CREATE
                picker.value.entity_id = props.entityId as number;
            }

            if (props.zone) {
                shape.value = getLastShapeFromZone(props.zone);

                drawZoneAndBound(shape.value);
            }
        }

        function getProvince(g: google.maps.GeocoderResult) {
            return g.address_components
                .find(x => x.types?.includes("administrative_area_level_2"))
                ?.short_name;
        }

        function getCityName(g: google.maps.GeocoderResult) {
            return g.address_components
                .find(x => x.types?.includes("locality"))
                ?.long_name;
        }

        function getPostalCode(g: google.maps.GeocoderResult) {
            return g.address_components
                .find(x => x.types?.includes("postal_code"))
                ?.short_name;
        }

        function onShow() {
            init(props?.initialValue as EntityPicker);
        }

        function onHide() {
            picker.value              = new EntityPicker();
            autocompleteAddress.value = '';
        }
        
        onMounted(() => {
            if (props.pickerType) {
                //@ts-ignore
                type.value = props.pickerType;
            }
        })

        return {
            // UI - Icona e titolo in base al tipo 
            title,
            isDropoff,
            isPickup,
            msg,
            errorOnSave,

            // Picker in edit/new
            picker,

            // Ricerca indirizzo
            autocompleteAddress,

            isServed,
            type,
            
            geocodeResult,

            onShow,
            onHide,

            onSelectAddress,

            getProvince,
            getCityName,
            getPostalCode,

            typeOptions: [
                { name: 'Ritiro', value: EntityPickerType.PICKUP },
                { name: 'Consegna', value: EntityPickerType.DROPOFF },
            ]
        }
    },

    computed: {
        sidebar() {
            const refs: any = this.$refs;
            return refs.sidebar;
        }
    },

    methods: {
        close() {
            this.sidebar.hide();
        },

        onSubmit() {
            this.picker.type = this.type;

            if (!this.emitOnly) {
                this.save();
            } else {
                this.$emit('newPicker', this.picker);
                this.close();
            }
        },
        
        async save() {
            this.errorOnSave = false; 
        
            const type = this.picker.type === EntityPickerType.DROPOFF 
                ? "consegna" 
                : "ritiro";
        
            const successMessage: string = 
              `Punto di ${type} ${this.picker.id ? 'aggiornato' : 'creato'}`;
        
            const errorMessage: string = 
              `
                ${this.picker.id ? 'Aggiornamento' : 'Creazione'} 
                punto di ${type} non riuscito
              `;
              
            try {
                if (this.picker.id) {
                    await entityPickersService.updatePatch(this.picker);
                  } else { 
                    await entityPickersService.create(this.picker);
                  }
          
                  this.$successMessage(successMessage);
                  this.$emit('saved')
                  this.close();

            } catch (e: any) {
                if ( e.status === 422){
                    const errors = e.data.errors;

                    const result = Object.keys(errors).map( k => {
                        return (errors[k] as string[]).flat();
                    }).flat();

                    this.msg = result[0];
                    this.errorOnSave = true; 
                }
                this.$errorMessage(errorMessage);
            } 
        }
    }

})