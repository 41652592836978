import { Shift }           from "@/model/Shift";
import { shiftsService }   from "@services/shift.service";
import { Options, Vue }    from "vue-class-component";
import { Prop }            from "vue-property-decorator";

export declare class ITimeShiftAutocomplete {
  $props: {
    ids : number[];
  }

  $emit(eventName: 'select', value: Shift): any;
}

@Options({})
export default class TimeShiftAutocomplete extends Vue {
  @Prop({ required: true }) readonly ids!: number[];

  options: Shift[] = null;

  searchBox  : string | Shift = null;
  suggestions: Shift[]        = null;

  search({ query }) {
    const x = (query as string).toLowerCase().trim();

    this.suggestions = this.options
      .filter(
        v => 
          (!this.ids.includes(v.id)) 
          && v.name.toLowerCase().includes(x)
      );
  }

  onSelect({ value }: { value: Shift }) {
    this.$emit('select', value);
    this.searchBox = null; 
  }

  private async loadOptions() {
    this.options = await shiftsService.getAll();
  }
  
  mounted() {
    this.loadOptions();
  }
}