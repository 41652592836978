
  import { Loader } from "@googlemaps/js-api-loader";
  import { toRaw } from "vue";
  import { Options, Vue } from "vue-class-component";
  import { Model, Prop } from "vue-property-decorator";
  import { configuration } from "@plugins/Config-plugin";
import { mapStyles } from "@/utils/gmap_utils";

  @Options({})
  export default class AppMap extends Vue {
    @Prop() readonly bounds?: google.maps.LatLngBounds;

    @Prop() readonly address?: string;

    @Model('location') 
    latLng?: google.maps.LatLng;

    map       : google.maps.Map        = null;
    marker    : google.maps.Marker     = null;
    infowindow: google.maps.InfoWindow = null;

    get rawMap() {
      return toRaw(this.map);
    }

    drawMarkerAndInfowindow(location, infoWindowText) {
      this.marker.setMap(this.rawMap);
      this.marker.setPosition(location);

      this.infowindow.setContent(infoWindowText);
      this.infowindow.open(this.rawMap, this.marker);
    }

    private async _loadGoogle() {
      const mapLoader = new Loader({
        apiKey    : configuration.mapApiKey,
        version   : configuration.mapVersion,
        libraries : ["places"],
        language  : "it",
      });

      if (!window.google) {
        await mapLoader.load();
      }
    }

    private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {
      const center = configuration.startCenter;
      const zoom   = configuration.startZoom;

      this.map = new google.maps.Map(
        element, 
        { 
          center, 
          zoom,
          styles : mapStyles
        }
      );

      if (bounds) {
        this.map.fitBounds(bounds, 0);
      }

      this.map.data.setStyle({
        strokeWeight  : 1,
        strokeOpacity : 1,
        strokeColor   : "#3399FF",
        fillColor     : "#3399FF",
        fillOpacity   : 0.2,
        editable      : true,
        draggable     : true,
        clickable     : true,
        zIndex        : 1,
      });
    }

    private _initMarker() {
      this.marker = new google.maps.Marker({ draggable: true });

      this.marker.addListener("dragend", (e: google.maps.MapMouseEvent) => {
        this.onDragend(e.latLng);
      });
    }

    private onDragend(latLng: google.maps.LatLng) {
      console.debug("TODO. Evento ?  ", latLng);
    }

    private _initInfoWindow() {
      this.marker = new google.maps.Marker({ draggable: true });
    }

    async created() {
    }

    async mounted() {
      await this._loadGoogle();

      this._buildMap(this.$refs.mapEl as HTMLElement, this.bounds);

      this._initMarker();

      this._initInfoWindow();
    }
  }
