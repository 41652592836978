
import { defineComponent, ref } from "vue"
import { domainService }        from "@services/domain.service";
import { BaseApiFilter } from "@/model/filters/BaseApiFilter";

export default defineComponent({
  name: 'CityAutocomplete',

  props: ['modelValue'],
  emits: ['update:modelValue'],

  setup() {
    const suggestions = ref([])

    async function onComplete({ query }: { query: string }) {
      const response = await domainService.cities(
        BaseApiFilter.fromGlobal(
          query,
          {
            sortField: 'name',
            sortOrder: 1
          }
        )
      );

      const q = query.toLowerCase();

      /**
       * Metto in evidenza le città che iniziano con la stringa inserita
       */
      response.data.sort( (a,b) => {
        const a_StartsWithQ = a.name.toLowerCase().startsWith(q);
        const b_StartsWithQ = b.name.toLowerCase().startsWith(q);

        if (a_StartsWithQ && !b_StartsWithQ) {
          return -1; 
        } else if (!a_StartsWithQ && b_StartsWithQ) {
          return 1; 
        } else {
          return a.name.localeCompare(b.name)
        } 
      })

      suggestions.value = [...response.data]
    }

    return {
      suggestions,
      onComplete,
    }
  }
})
