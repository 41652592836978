export interface ChatMessage {
    id: number;
    thread_id: number;

    /**
     * If null is Admin
     */
    created_entity_id: number;

    message: string;
    created_at: Date;

    /**
     * just for ui
     */
    entity1?: { name: string, surname: string };
}

export interface MessageEntity {
    id: number;
    name: string;
    surname: string;
    business_name: string;
}

export interface Thread {
    id?: number;

    /**
     * If entity 1 has read the last message
     */
    entity1_has_read?: boolean;

    /**
     * If entity 2 has read the last message
     */
    entity2_has_read?: boolean;

    /**
     * Identity of entity involved in the thread
     * NB: If null, is Admin
     */
    entity1?: MessageEntity;
    entity_id1?: number;

    /**
     * Identity of entity involved in the thread
     * NB: If null, is Admin
     */
    entity2?: MessageEntity;
    entity_id2?: number;

    last_message_at?: Date;
    last_message_entity_id?: number;

    entity_email_sent?: boolean;
}

export class SendMessageModel {
    entity_ids: number[];
    text: string;
}
