
import { defineComponent } from "vue";

interface AlertDialogProps {
  showAlertMessage: boolean;
  alertMessage: string;
}

export default defineComponent({
  props: [ 
    'showAlertMessage', 
    'alertMessage' 
  ],

  setup(p: AlertDialogProps, { emit }) {
    
    function close() {
      emit('update:showAlertMessage', false);
    }

    return {
      close
    }
  }
})
