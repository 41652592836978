import { Vehicle }          from "@/model/Vehicle";
import { Zone }             from "@/model/Zone";
import { Options, Vue }     from "vue-class-component";
import { Prop }             from "vue-property-decorator";
import PriceAndCost         from "../PriceAndCost/PriceAndCost.vue"
import VehicleAutocomplete  from "../../VehicleAutocomplete/VehicleAutocomplete.vue"

@Options({
  components: {
    PriceAndCost,
    VehicleAutocomplete
  }
})
export default class ZoneMainPricesAndCost extends Vue {
  @Prop() readonly zone!: Zone;

  get selectedVehicleIds(){
    return this.zone.vehicle_data?.map(v => v.vehicle_id) || [];
  }


  onSelect(value : Vehicle) {
    this.zone.vehicle_data = [
      ...(this.zone.vehicle_data || []),
      {
        id          : null,
        extra_cost  : null,
        extra_price : null,
        entity_id   : null,
        is_enabled  : false,
        vehicle_id  : value.id,
        zone_id     : this.zone.id,
        vehicle     : value
      }
    ];
  }

  removeVehicle(v){
    this.$confirmMessage("Confermi la rimozione della tipologia selezionata?")
    .then(r => {
      if (r) {
        const idx = this.zone.vehicle_data?.indexOf(v);
        this.zone.vehicle_data.splice(idx, 1);
      }
    })
  }

}