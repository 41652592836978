
import { Shift } from '@/model/Shift'
import { computed, defineComponent, toRef } from 'vue'

interface ShiftToTextProps {
  shift: Shift
}

export default defineComponent({
  props: {
    shift: {
      required: true,
    }
  },
  setup(props: ShiftToTextProps) {
    const shift = toRef(props, 'shift');

    const text = computed(() => {
      if (!shift.value) return "";
      const { start_time: st, end_time: et} = shift.value;
      return `${st?.substring(0, 5)} | ${et?.substring(0, 5)}`;
    })

    return {
      text
    }
  },
})
